@import '../../App.scss';


.Login{
    .error{
        color:red;
    }
    .section{
        display:flex;
        flex-direction:column;
        align-items:center;
        justify-content:center;
    }
    .form {
        display:flex;
        flex-direction:column;
        align-items:stretch;
        max-width:25em;
        width:100%;
        margin:0 auto;
        .field{
            margin-bottom:1em;
        }

        .or-hr{
            position:relative;
            hr{
                background:#777777
            }
            span{
                left: 50%;
                top: 50%;
                position: absolute;
                transform: translate(-50%, -50%);
                padding: 0 0.5em;
                background: white;
            }
        }

        a.register-link{
            color:#1976d2;
            text-decoration:underline;
            font-weight:normal;
            text-align: center;
            cursor:pointer;
        }
        a.forgot-password-link{
            color:#1976d2;
            text-decoration:underline;
            font-weight:normal;
            cursor:pointer;
        }
    }
}