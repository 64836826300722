@import '../../App.scss';


.BoardMembers{
    .section{
        .grid{   
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            grid-gap: 2em;
            .tile{
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
                aspect-ratio: 1;
                position:relative;
                box-shadow: 0 0 20px #00000026;
                .banner{
                    position:absolute;
                    left:0;
                    right:0;
                    bottom:0;
                    height:20%;
                    background:rgba(0, 0, 0, 0.493);
                    color:white;
                    padding:1em;
                    .name{
                        font-weight:bold;
                    }
                    .title{
                        font-style:italic;
                    }
                }
            }
        }
    }

    @media (max-width:1190px) {
        .grid{ 
            grid-template-columns: 2fr 2fr !important;  
        }
        .banner{
            height:50% !important;
        }
    }
    
}