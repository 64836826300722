@import '../../App.scss';

.Header {
    
    .desktop{
        height: 8em;
        display:flex;
        justify-content:space-between;
        align-items:center;
    }
    .mobile{
        height: 4em;
        display:none;
        justify-content: space-between;
        align-items:center;
        padding:0 1em;
        .mobile-menu{
            font-size:1.5em;
            border:0;
            outline:0;
            background:0;
        }
        .profile-button{
            margin-left:0;
        }
        .profile-popup{
            right: 0;
            left: auto !important;
            transform: none !important;
        }
        a.logo{
            margin-left:0;
            img{
                height:3em;
            }
        }
    }

    .logo{
        margin-left:3em;
        img{
            height: 4.5em;
        }
    }
    .menu-bar{
        margin-right:2.5em;
        display:flex;
        align-items:center;
        list-style: none;
        a{

            padding:0.5em;
            margin-left:1em;
            color:darken($primary, 20%);
            font-weight:bold;
            text-decoration:none;
            &:hover{
                color:$primary;
            }
            &.login{
                font-weight:700;
                padding-top: 0;
                cursor:pointer;
            }
        }
        a.active{
            color:$primary;
        }
        .CTA{
            margin-bottom: -1.3em;
            display:flex;
            flex-direction:column;
            align-items:center;
            margin-left:1em;
            a{
                margin-left:0;
            }
            a.membership{
                display:block;
                background:$primary;
                color:white;
                border-radius: 0.5em;
                font-size:1.3em;
                padding:0.6em 1em;
                text-align:center;
                width: 8em;
                &:hover{
                    background:lighten($primary, 10%);
                    color:white;
                }
                &.active{
                    color:white;
                }
            }
            a.account{
                // display:block;
                // border:1px solid $primary;
                // color:$primary;
                // border-radius: 0 0 0.5em 0.5em;
                font-size:0.9em;
                display:block;
                text-decoration: underline;
                font-weight:normal;
                text-align: center;
                padding:0;
                // padding:0.6em 1em;
            }
            
        }
    }

    .profile{
        position:relative;
        .profile-button{
            background:0;
            margin-left:1.5em;
            border:0;
            font-size:1.2em;
            cursor:pointer;
            display:flex;
            align-items:center;
            img{
                height:2em;
                border-radius:2em;
                margin-right:0.6em;
            }
        }
        .profile-popup-container{
            position: fixed;
            width: 100vw;
            height: 100vh;
            left: 0;
            top: 0;
            z-index: 1000;
        }
        .profile-popup{
            display: inline-flex;
            background: white;
            color: black;
            flex-direction: column;
            box-shadow: 0 0 9px #00000021;
            position: absolute;
            left: 50%;
            top: calc(100% + 0.5em);
            transform:translate(-50%, 0);
            z-index: 1001;
            .greeting{
                padding:1em 2em;
            }
            .link {
                padding-right:4em;
                cursor:pointer;
                padding-left:2em;
                margin-left:0;
                color:black;
                font-weight:normal;
                display:flex;
                align-items: center;
                white-space: nowrap;
                svg{
                    margin-right:0.5em;
                    color:grey;
                }
                &:hover{
                    background:rgb(235, 235, 235);
                    color:black;
                }
            }
            hr{
                height: 1px;
                width: 100%;
                margin: 0;
            }
        }
    }
    
   

    
}

@media (max-width:1190px) {
    .Header{
        .desktop{
            display:none;
        }
        .mobile{
            display:flex;
        }
    }

    .menu-bar{
        padding-inline-start: 0;
        li.menu-item{
            padding:1em 2em 1em 2em;
            a{
                text-decoration:none;
            }
        }
        .CTA{
            display:flex;
            flex-direction:column;
            align-items:center;
            a{
                margin-left:0;
            }
            a.membership{
                display:block;
                background:$primary;
                color:white;
                border-radius: 0.5em;
                font-size:1.3em;
                padding:0.6em 1em;
                text-align:center;
                width: 8em;
                &:hover{
                    background:lighten($primary, 10%);
                    color:white;
                }
                &.active{
                    color:white;
                }
            }
            a.account{
                // display:block;
                // border:1px solid $primary;
                // color:$primary;
                // border-radius: 0 0 0.5em 0.5em;
                font-size:0.9em;
                display:block;
                text-decoration: underline;
                font-weight:normal;
                text-align: center;
                padding:0;
                // padding:0.6em 1em;
            }
            
        }
        .profile-popup{
            a{
                display:block;
                padding-top:1em;
                color:black;
                font-weight:normal;
                font-size:0.8em;
                font-weight:500;
               
            }
        }
       
        
    }
}
