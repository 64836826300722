@import '../../App.scss';


.About{

  .partnership-row{
    display:flex;
    align-items:center;
    p:first-child{
      width:30%;
      img{
        width:100%;
      }
    }
  }

  h2{
    max-width:512px;

    span{
      color:$primary;
    }
  }
  &.dark{
    background:#f2f2f2;
    padding:0 2em;
    margin:0 auto;
    .card{
      background:#f2f2f2;
      padding: 2em;
    }
    h2{
      text-align:left;
      margin:1em 0;
    }
  }
  .tiles{
    display:flex;
    justify-content:center;
    align-items:stretch; 
    .tile{
      width:33.33%;
      padding:3em;
      h3{
        color:darken($primary, 20%);
      }
      img{
        height:5em;
      }
    }
  }
  .quote-container{
    padding: 3em 2em;
    margin-right:2em;
    background:darken($primary, 20%);
    text-align: left;
    position:relative;
    .quote-icon {
      color:white;
      font-size:3em;
      margin-left: 0.5em;
    }
    .quote-text{
      color:white;
      text-align:left;
    }
    img{
      float: right;
      position: relative;
      margin-bottom: -6em;
      margin-left: -2em;
      top: -7em;
      right: -4em;
    }
    .author{
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
      color: white;
      font-weight: 600;
      bottom: 3em;
    }
  }
  .tiles{
    display:flex;
    justify-content:center;
    align-items:stretch; 
    .tile{
      width:33.33%;
      h3{
        color:darken($primary, 20%);
      }
      img{
        height:5em;
      }
    }
      
  }

  @media (max-width:1190px) {
    .tiles{
      display:block;
      .tile{
        width:100%;
        padding:0;
      }
    }
  }
}


