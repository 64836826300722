@import '../../App.scss';


.Register{
    .section{
        display:flex;
        flex-direction:column;
        align-items:center;
        justify-content:center;
        .checkout-layout{
            display:flex;
            .left{
                max-width:40%;
            }
            .right{
                flex-grow: 1;
                padding-left:6em;
            }
            .selected-ml{
                display:flex;
                justify-content: space-between;
                align-items: center;
                h3{
                    display:flex-item;
                }
            }
            .ml-description{
                font-size:0.8em;
            }
            .or{
                position:relative;
                margin:2em 0;
                span{
                    color:grey;
                    padding:0.5em;
                    background:white;
                    position:absolute;
                    left:50%;
                    top:50%;
                    transform:translate(-50%, -50%);
                }
            }
        }
    }
    .form {
        display:flex;
        flex-direction:column;
        align-items:stretch;
        max-width:25em;
        width:100%;
        margin:0 auto;
        .field{
            margin-bottom:1em;
        }

        .or-hr{
            position:relative;
            hr{
                background:#777777
            }
            span{
                left: 50%;
                top: 50%;
                position: absolute;
                transform: translate(-50%, -50%);
                padding: 0 0.5em;
                background: white;
            }
        }

        a.register-link{
            color:#1976d2;
            text-decoration:underline;
            font-weight:normal;
            text-align: center;
            cursor:pointer;
        }
        a.forgot-password-link{
            color:#1976d2;
            text-decoration:underline;
            font-weight:normal;
            cursor:pointer;
        }
    }
}