@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

$primary: #2fbcb8;
$secondary: #ec4047;
$dark: darken($primary, 20%);

a{
  color: darken($primary, 10%);
  font-weight: bold;
}

ul {
  list-style: none; /* Remove default bullets */
}

ul li::before::not(.menu-item) {
  content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: darken($primary, 10%); /* Change the color */
  font-weight: bold; /* If you want it to be bold */
  display: inline-block; /* Needed to add space between the bullet and the text */
  width: 1em; /* Also needed for space (tweak if needed) */
  margin-left: -1em; /* Also needed for space (tweak if needed) */
}




.App {
  font-family: 'Open Sans';
  min-height:100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.Page{
  flex-grow:1;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.section{
  max-width:1400px;
  padding:3em;
  margin:0 auto;
}
.inner{
  max-width:1400px;
}

hr{
  border: 0;
  background: #d1d1d1;
  height: 1px;
}

.button{
  padding:0.5em 2em;
  font-weight:bold;
  text-decoration:none;
  background:$primary;
  color:white;
  display:inline-block;
  border-radius:100px;
  &:hover{
      background:lighten($primary, 10%);
      color:white;
  }
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



.desktop {
  display:block;
} 
.desktop-flex{
  display:flex;
}
.desktop-inline{
  display:inline;
}

.mobile, .mobile-flex{
  display:none;
}

@media (max-width:1190px) {  
  .mobile {
    display:block;
  } 
  .mobile-flex{
    display:flex;
  }

  .desktop, .desktop-flex, .desktop-inline{
    display:none;
  }

  .section{
    padding:1em;
  }
}