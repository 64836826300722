@import '../../App.scss';


.Profile{
    .renew-link{
        text-decoration: underline;
        cursor:pointer;
    }
    .section{
        display:flex;
        flex-direction:column;
        align-items:center;
        justify-content:center;
        &.profile{
            align-items:stretch;
            flex-direction:row;
            .status-section{
                padding-right:2em;
                .status-badge{
                    // border:2px solid;
                    border-radius:0.6em;
                    font-weight:bold;
                    display:flex;
                    align-items:center;
                    justify-content:center;
                    font-size:2em;
                    padding:0.5em;
                    &.active{
                        color:#1ea9a5;
                        border-color:#1ea9a5;
                        background:#92e2e0;
                    }
                    &.inactive{
                        color: #ec4047;
                        border-color: #ec4047;
                        background: #ffbbbb;
                    }
                }
            }
            .separator{
                width:2px;
                background:#ababab;
            }
            .account-section{
                flex-grow:1;
                padding-left:2em;
                .form{
                    display:flex;
                    flex-direction:column;
                    align-items:flex-start;
                    max-width: 30em;
                    .form-field{
                        margin-bottom:1em;
                        width:100%;
                        &.no-margin{
                            margin-bottom:0;
                        }
                    }
                    .password-strength{
                        width:100%;
                        margin-bottom:1em;
                    }
                }
                
            }
        }
    }
    
    .learn-more-button{
        margin-left:2em;
        background:0;
        border:0;
        outline:0;

    }
    .hide-container{
        position:relative;
        .center-text{
            position:absolute;
            left:50%;
            top:7em;
            transform:translateX(-50%);
            text-align: center;
            z-index:1;
        }
        .cover{
            filter: blur(12px);
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: flex-start;
            
        }
        .block{
            -webkit-touch-callout: none; /* iOS Safari */
            -webkit-user-select: none; /* Safari */
             -khtml-user-select: none; /* Konqueror HTML */
               -moz-user-select: none; /* Old versions of Firefox */
                -ms-user-select: none; /* Internet Explorer/Edge */
                    user-select: none; /* Non-prefixed version, currently
                                          supported by Chrome, Edge, Opera and Firefox */
        }
    }
    

    .BannerTitle {
        background: #1ea9a5;
        color: white;
        margin:0;
        padding: 2em;
        box-shadow: 0px 7px 20px #00000040;
        &.no-pad{
            padding-bottom:0;
        }
        a{
            cursor:pointer;
            color:white;
            &:visited{
                color:white;
            }
            text-decoration: none;
            &:hover{
                text-decoration:underline;
            }
            
        }
        .breadcrumbs{
            display:block;
            max-width:1400px;
            margin:0 auto;
            span{
                text-transform: capitalize;
                i{
                    margin:0 0.5em;
                }
            }
        }
        h1{
            font-size:3em;
            margin:0;
            span{
                display:block;
                max-width:1400px;
                margin:0 auto;
            }
        }

        .profile-header{
            max-width:1400px;
            // display: flex; 
            display:block;
            align-items: center;
            margin:1em auto;
            img{
                height: 5em;
                width: 5em;
                border-radius: 5em;
                margin-right: 1em;
            }
        }

        .menu{
            max-width: 1400px;
            margin: 0 auto;
            ul{
                margin:0;
                display:flex;
                padding:0;
                li{
                    list-style:none;
                    list-style-type: none;
                    &:before{
                        display:none;
                    }
                    a{
                        display:inline-block;
                        padding:1em 2em 0.5em 2em;
                        border-bottom:4px solid transparent;
                        &.active{
                            border-bottom:4px solid white;
                        }
                        &:hover{
                            text-decoration: none;
                        }
                    }
                }
            }
        }
        
    }

    .benefits{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-auto-rows: 1fr;
    
        .block-container{
            width:100%;
            height:100%;
            flex-shrink: 0;
            padding:1em;
            box-sizing: border-box;
            .block{
                height:100%;
                box-shadow:0 0 black;
                // background:rgb(206, 206, 206);
                min-height:12em;
                border-radius:1em;
                display:flex;
                flex-direction: column;
                // justify-content: flex-end;
                align-items: flex-start;
                // border:2px solid;
                background-position: center;
                background-size: cover;
                overflow:hidden;
                .upper{
                    width:100%;
                    height:10em;
                    background-position: center;
                    background-size: cover;
                }
                .cover{
                    flex-grow: 1;
                    padding: 1em 2em;
                    background: #ffffffa1;
                    width: calc(100% - 4em);
                    border-radius: 1em;
                }
                .lower{
                    padding:2em;
                }
                .tip{
                    font-size:0.8em;
                    padding:0 0 0.2em 0.4em;
                }
                .benefit-title{
                    font-weight:bold;
                }
                .visit-site{
                    background:$primary;
                    color:white;
                    border:0;
                    outline:0;
                    padding:1em 2em;
                    border-radius:5em;
                    text-align:center;
                    cursor:pointer;
                    // margin-top:2em;
                }
                .code-container{
                    margin:1em 0;
                }
                .code-label{
                    font-size:0.8em;
                }
                .lower{
                    flex-grow: 1;
                    display:flex;
                    flex-direction: column;
                    // justify-content: space-between;
                }
                .input-outline{
                    // border:1px solid black;
                    background:white;
                    padding:0.5em 1em;
                    border-radius:1em;
                    width:auto;
                    display:flex;
                    .copy-button{
                        background: $primary;
                        border:0;
                        outline:0;
                        border-radius:0.4em;
                        color:white;
                        font-weight:bold;
                        cursor:pointer;
                    }
                    .code-input{
                        font-weight:bold;
                        border:0;
                        outline:0;
                        &:focus{
                            outline:0;
                        }
                        // filter:blur(10px);
                        transition: filter 0.3s;
                        background:0;
                        padding:0.5em 1em;
                        
                    }
                }
            }
        }

    }

    @media (max-width:1190px) {
        .profile-header{
            h3{
                margin-top:1em !important;
            }
        }

        .section.profile{
            display:block;
        }

        .account-section{
            margin-top:3em;
            padding-left:0 !important;
        }

        .benefits{
            display:block;
            .block-container{
                width:auto;
            }
        }
    }
}
