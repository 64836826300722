@import '../../App.scss';


.Education{
    .no-events{
      background: #f2f2f2;
      font-weight: bold;
      color: #5c5c5c;
      padding: 2em;
    }
    .event-tiles{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-gap: 2em;
        .event-tile{
          border: 1px solid #67b8b7;
          cursor:pointer;
          // aspect-ratio: 1;
          text-align:left;
          background:#f2f2f2;
          color:#385050;
          display:flex;
          flex-direction:column;
          // justify-content: space-between;
          img{
            width: 100%;
            height: auto;
          }
          .inner{
            padding:2.5em;
          }
          .upper{
            margin-bottom:1em;
          }
          &:hover{
            filter:opacity(0.85);
          }
          &.primary {
            background:$primary;
            color:white;
          }
          .date{
            font-size:4em;
            line-height: 1em;
          }
          .month{
            font-weight:bold;
            font-size:1.3em;
          }
          .title{
            font-size:1.2em;
            margin-bottom:0.5em;
          }
          .time, .location{
            font-weight:bold;
          }
        }
        
      }
}