@import '../../App.scss';


.Home {
  text-align: center;
  .hero{
    display:flex;
    height:70vh;
    position:relative;
    margin-bottom:7em;
    .left{
      
    }
    .right{
      width:100%;
      background-position: center;
      background-size: cover;
    }
    .card{
      background:#f2f2f2;
      color:darken($primary, 20%);
      position:absolute;
      left:3em;
      bottom:-5em;
      width:calc(50vw - 3em);
      text-align:left;
      padding:2em;
      max-width:720px;
      h1{
        margin:0;
        color:$dark;
        background:0;
        padding:0;
        margin:0;
      }
      hr{
        height:0.2em;
        border:0em;
        width:5em;
        margin:1em 0;
        background:$secondary;
      }
      .membership{
        padding:0.5em 2em;
        font-weight:bold;
        text-decoration:none;
        background:$primary;
        color:white;
        display:inline-block;
        border-radius:100px;
        &:hover{
            background:lighten($primary, 10%);
            color:white;
        }
      }
    }
  }

  .section{
    padding:2em;
    h1{
      max-width:512px;
      margin:0 auto;
      color:$dark;
      span{
        color:$primary;
      }
    }
    &.dark{
      background:#f2f2f2;
      padding:0 2em;
      .card{
        background:#f2f2f2;
        padding: 2em;
      }
      h2{
        text-align:left;
        margin:1em 0;
      }
    }
    .tiles{
      display:flex;
      justify-content:center;
      align-items:stretch; 
      .tile{
        width:33.33%;
        padding: 0 2em;
        h3{
          color:darken($primary, 20%);
        }
        img{
          height:5em;
          margin-top: 2em;
          filter: opacity(0.9);
        }
      }
    }
    .quote-container{
      padding: 3em 2em;
      margin-right:2em;
      background:darken($primary, 20%);
      text-align: left;
      position:relative;
      .quote-icon {
        color:white;
        font-size:3em;
        margin-left: 0.5em;
      }
      .quote-text{
        color:white;
        text-align:left;
      }
      img{
        float: right;
        position: relative;
        margin-bottom: -6em;
        margin-left: -2em;
        top: -7em;
        right: -4em;
        max-width: 30em;
        width:100%;
      }
      .author{
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0);
        color: white;
        font-weight: 600;
        bottom: 3em;
      }
    }
    h1.leftAlign{
      text-align:left;
      font-size: 2em;
      margin:0;
      margin-block-start: 0.67em;
      margin-block-end: 0.67em;
    }
    .no-events{
      background: #f2f2f2;
      font-weight: bold;
      color: #5c5c5c;
      padding: 2em;
    }
    .event-tiles{
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-gap: 2em;
      .event-tile{
        border: 1px solid #67b8b7;
        cursor:pointer;
        // aspect-ratio: 1;
        text-align:left;
        background:#f2f2f2;
        color:#385050;
        display:flex;
        flex-direction:column;
        // justify-content: space-between;
        img{
          width: 100%;
          height: auto;
        }
        .inner{
          padding:2.5em;
        }
        .upper{
          margin-bottom:1em;
        }
        &:hover{
          filter:opacity(0.85);
        }
        &.primary {
          background:$primary;
          color:white;
        }
        .date{
          font-size:4em;
          line-height: 1em;
        }
        .month{
          font-weight:bold;
          font-size:1.3em;
        }
        .title{
          font-size:1.2em;
          margin-bottom:0.5em;
        }
        .time, .location{
          font-weight:bold;
        }
      }
      
    }
  }

  @media (max-width:970px) {
    .card{
      width:auto !important;
      left:3em;
      right:3em;
    }
  }

  @media (max-width:400px) {
    .card{
      bottom: auto !important;
      top: 10em;
    }
  }

  @media (max-width:1190px) {

    .hero{
      height:auto;
      display:block;
      margin-bottom:0;
      .right{
        height:30vh;
      }
      .card{
        width:100%;
        max-width:none;
        position:static;
        padding:1em;
      }
    }
    .tiles{
      display:block !important;
      margin:0 -1em;
      .tile{
        width:calc(100% - 2em) !important;
        padding:1em !important;
        &:nth-child(1) {
          background:#eeeeee;
        }
        &:nth-child(2) {
          background:#fafafa;
        }
        &:nth-child(3) {
          background:#eeeeee;
        }
      }
    }
    .section{
      padding:1em;
      &.dark{
        padding:0 !important;
        .card{
          padding:1em;
        }
      }
    }
    p{
      text-align:left;
    }
    h3{
      text-align:left;
    }
    .event-tiles{
      display:block !important;
      .event-tile{
        margin-bottom:1em;
        aspect-ratio: unset !important;
      }
    }
    .quote-container{
      margin-right:0 !important;
      padding:1em !important;
      img{
        float:none !important;
        position:static !important;
        margin:0 !important;
      }
    }
    .author{
      position:static !important;
      transform:none !important;
      margin-top:1em;
    }
  }
}