@import '../../App.scss';

.BannerTitle {
    background: #1ea9a5;
    color: white;
    margin:0;
    padding: 2em;
    box-shadow: 0px 7px 20px #00000040;
    &.no-pad{
        padding-bottom:0;
    }
    a{
        color:white;
        &:visited{
            color:white;
        }
        text-decoration: none;
        &:hover{
            text-decoration:underline;
        }
        
    }
    .breadcrumbs{
        display:block;
        max-width:1400px;
        margin:0 auto;
        span{
            text-transform: capitalize;
            i{
                margin:0 0.5em;
            }
        }
    }
    h1{
        font-size:3em;
        margin:0;
        span{
            display:block;
            max-width:1400px;
            margin:0 auto;
        }
    }

    .profile-header{
        max-width:1400px;
        display: flex; 
        align-items: center;
        margin:1em auto;
        img{
            height: 5em;
            width: 5em;
            border-radius: 5em;
            margin-right: 1em;
        }
    }

    .menu{
        max-width: 1400px;
        margin: 0 auto;
        ul{
            margin:0;
            display:flex;
            padding:0;
            li{
                list-style:none;
                list-style-type: none;
                &:before{
                    display:none;
                }
                a{
                    display:inline-block;
                    padding:1em 2em 0.5em 2em;
                    border-bottom:4px solid transparent;
                    &.active{
                        border-bottom:4px solid white;
                    }
                    &:hover{
                        text-decoration: none;
                    }
                }
            }
        }
    }
    
}