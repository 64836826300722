@import '../../App.scss';



.Footer{
    color:white;
    margin-top:4em;
    padding:2em;
    background:darken($primary, 20%);
    a{
        color:white;
        &:hover{
            color:white;
        }
    }
    .inner{
        margin:0 auto;
        display:flex;
        justify-content:space-around;
        align-items: stretch;
        .contact-us{
            text-align:left;
            flex-grow:1;
            svg{
                margin-right:0.5em;
            }
        }
        > div{
           // padding:0 6em;
        }
        .links{
            text-align:left;
            column-count:3;
            padding-top:1em;
            flex-grow:1;
            //padding-left:6em;
            a{
                text-decoration: none;
                font-weight:bold;
                display:block;
                margin-bottom:1em;
            }
        }
        .connect{
            text-align:right;
            flex-grow:1;
            a{
                margin: 0 0.6em;
            }
            i{
                font-size:2em;
            }
        }
    }

    .copyright{
        text-align:center;
    }

    @media (max-width:1190px) {
        .inner{
            display:block;
        }

        h3{
            text-align:left;
        }
        .connect{
            text-align:left !important;
        }
    }
}