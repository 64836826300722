@import '../../App.scss';

.PasswdProtection {
   .PasswdProtectionOuter{
       position:fixed;
       background:white;
       width:100vw;
       height:100vh;
       display:flex;
       align-items:center;
       justify-content:center;
       z-index:10;
       .PasswdProtectionInner{
            background:white;
            .form{
                display:flex;
                justify-content: center;
                input{
                    font-size: 22px;
                    padding:5px 10px;
                    border: 2px solid $dark;
                    border-right:0;
                    border-radius: 0.4em 0 0 0.4em;
                    display:block;
                    &:focus{
                        outline:0;
                    }
                }
                button{
                    font-size: 22px;
                    padding:5px 10px;
                    border:2px solid $dark;
                    background:$dark;
                    color:white;
                    border-radius: 0 0.4em 0.4em 0;
                    display:block;
                    cursor:pointer;
                }


                &.failed{
                    input{
                        border: 2px solid red;
                    }
                }
            }
            
       }
   }
}