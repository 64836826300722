@import '../../App.scss';


.Membership{
    .slider{
        margin-bottom: 4em;
        li:before{
            color:transparent;

        }
        .slick-dots{
            position: relative;
            top: 2em;
        }
        .tile-inner{
            padding:0 1em;
        }
        .slick-prev:before, .slick-next:before{
            color:#6e6e6e;
        }

    }
    .more-accordion{
        max-width: 32em;
        width: 100%;
        margin:4em auto;
    }
    .grey-box{
        background:rgb(239, 239, 239);
    }
    p{
        margin:0;
    }
    .tiles{
        display:flex;
        justify-content:center;
        align-items:stretch; 
        .tile{
          width:33.33%;
          padding: 0 2em;
          display: flex;
          flex-direction: column;
          align-items: center;
          h3{
            color:darken($primary, 20%);
          }
          img{
            height:5em;
          }
        }
        .slide{
            min-width: none;
        }
    }
    // .carousel{
    //     .tile{
    //         width:33.33%;
    //     }
    // }
    .tile{
        text-align: center;
        img{
            margin:0 auto;
        }
    }
    a{
        color:white;
        &:visited{
            color:white;
        }
        text-decoration: none;
        &:hover{
            text-decoration:underline;
        }
        
    }
    .breadcrumbs{
        display:block;
        max-width:1400px;
        margin:0 auto;
        span{
            text-transform: capitalize;
            i{
                margin:0 0.5em;
            }
        }
    }
    h1{
        font-size: 2.5em;
    }
  .big-box{
      background:#1ea9a5;
      color:white;
      .call-to-action{
          font-size:1.5em;
          margin: 1em 0;
      }
      .button{
        padding:0.8em 2.5em;
        font-weight:bold;
        text-decoration:none;
        
        color:white;
        display:inline-block;
        border-radius:100px;
        text-transform: uppercase;
     
        &.primary{
            background:$secondary;
            border:2px solid $secondary;
            cursor:pointer;
            &:hover{
                background:lighten($secondary, 10%);
                border-color:lighten($secondary, 10%);
            }
        }
        &.secondary{
            background:none;
            border:2px solid white;
            &:hover{
                background:rgba(255, 255, 255, 0.192);
            }
        }
      }
  }
  .section.why, .section.policy, .section.members-talk{
      h1{
          text-align: center;
      }
  }
  .why{
      img{
        margin-top: 2em;
        filter: opacity(0.9);
        max-width: 8em;
      }
  }
  .policy{
    padding-bottom: 8em;
  }
  .members-talk{
      .big-tiles{
        display:flex;
        justify-content:center;
        align-items:stretch; 
        flex-wrap: wrap;
      }
      
      .row{
          button.expand{
              background:0;
              border:0;
              display:flex;
              align-items:center;
              cursor:pointer;
              border-radius:2em;
              padding:0.3em 1em;
              margin-top:0.4em;
              &:hover{
                  background:#f0f0f0;
              }
          }
            width:50%;
            flex-shrink:0;
            padding-bottom:3em;
            .left{
                width:30%;
                flex-shrink: 0;
            }
            h3{
                margin-bottom:0;
            }
            p.intro{
                margin-bottom:1em;
                color: #1ea9a5;
                font-weight: bold;
            }
            p.long{

                display:block;
            }
          .flex{
              display:flex;
              .img{
                width: 8em;
                height: 8em;
                margin: 0 auto;
                border-radius:100em;
                background-size: cover;
                background-position: center;
                image-rendering: crisp-edges;
                image-rendering: -moz-crisp-edges;          /* Firefox */
                image-rendering: -o-crisp-edges;            /* Opera */
                image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming)*/
                -ms-interpolation-mode: nearest-neighbor;   /* IE (non-standard property) */
              }
          }
      }
  }

  .members-talk .mobile{
    display:none!important;
    }

  @media (max-width:1190px) {
    h1{
        margin:0.5em 0 !important;
        font-size:2em;
    }

    .call-to-action{ 
        font-size: 1.5em;
        margin: 0.5em 0;
    }
    a.button{
        margin-bottom:1em;
    }
    .slick-next{
        right:0;
    }
    .slick-prev{
        left:0;
    }

    .big-tiles{
        display:block;
        .row{
            width:100%;
            .left{
                width:auto;
            }
            .right{
                padding-left:1em !important;
            }
            .long{
                margin-top: 1em;
            }
        }
    }
    .members-talk .mobile{
        display: block!important;
    }
  }
}