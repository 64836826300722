@import '../../App.scss';


.Certification{
    .profile-row{
        display: flex;
        align-items: stretch;
        .left{
            img{
                height: 21em;
            }
        }
        .right{
            background: #3f9390;
            color: white;
            display: flex;
            flex-direction: column;
            justify-content: center;
            .quote-container{
                display:flex;
                align-items:center;
                svg{
                    font-size:3em;
                    margin:0 1em;
                }
            }
            .signature{
                text-align: center;
                font-style: italic;
            }
        }
    }

    @media (max-width:1190px) {
        .profile-row{
            display:block;
            .left{
                background:#3f9390;
            }
            .quote-container{
                display:block !important;
                svg{
                    font-size: 2em;
                    margin: 0.5em auto !important;
                    display: block;
                }
                p{
                    margin:0 2em;
                }
            }
            .signature{
                margin:1em;
            }
        }
    }
}