@import '../../App.scss';


.RegisterModal{
    .error{
        color:red;
    }
    .register-modal{
        margin:-32px;
        width:60em;
        .no-margin{
            margin:0;
        }
        .inner{
            display:flex;
            flex-direction:row;
        }
        .left, .right{
            padding:3em;
        }
        .left{
            background: #ededed;
            border-top-left-radius: 1em;
            border-bottom-left-radius: 1em;
            position:relative;
            overflow:hidden;
            .logo{
                max-width: 15em;
                display: block;
                padding: 4em 4em 12em 4em;
            
            }
            .lower-art{
                width:150%;
                position:absolute;
                left:50%;
                bottom:0;
                transform:translateX(-50%);
                opacity:0.7;
            }
        }
        .right{
            .subtitle{
                font-weight: bold;
                color: #287674;
            
            }
            h1{
                margin:0.3em 0;
            }
        }
    }
    .backButton{
        padding:0;
        margin-right:1em;
    }
    .form {
        display:flex;
        flex-direction:column;
        align-items:stretch;
        max-width:25em;
        width:100%;
        margin:0 auto;
        .field{
            margin-bottom:1em;
            &.phone{
                width:100%;
            }
        }
        
        .or-hr{
            position:relative;
            hr{
                background:#777777
            }
            span{
                left: 50%;
                top: 50%;
                position: absolute;
                transform: translate(-50%, -50%);
                padding: 0 0.5em;
                background: white;
            }
        }

        a.register-link{
            color:#1976d2;
            text-decoration:underline;
            font-weight:normal;
            text-align: center;
            cursor:pointer;
        }
        a.forgot-password-link{
            color:#1976d2;
            text-decoration:underline;
            font-weight:normal;
            cursor:pointer;
        }
    }

    @media (max-width:1190px) {
        .inner{
            display:block !important;
        }
        .left{
            padding:0 !important;
            background:0 !important;
        }
        .right{
            padding:0 !important;
        }
        .register-modal{
            max-width:100%;
            margin:0;
        }
    }

}